<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_invoice_details')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card >

            <b-card-header class="pb-50">
                <h5>{{$t('label_invoice_details')}}</h5>
            </b-card-header>

            <b-card-body>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class=""
                >

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_company')"
                            slim
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_company')"
                                class="required"
                        >
                            <b-form-input
                                    :placeholder="$t('label_company')"
                                    v-model="itemData.company"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_address')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_address')"

                        >
                            <b-form-textarea
                                    :placeholder="$t('label_address')"
                                    :state="getValidationState(validationProps)"
                                    v-model="itemData.address"
                            ></b-form-textarea>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_email')"
                            slim
                            rules="email"
                    >
                        <b-form-group
                                :label="$t('label_email')"
                                class=""
                        >

                            <b-form-input
                                    :placeholder="$t('label_email')"
                                    v-model="itemData.email"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_phone')"
                            slim
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_phone')"
                                class=""
                        >

                            <b-form-input
                                    :placeholder="$t('label_phone')"
                                    v-model="itemData.phone"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_website')"
                            slim
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_website')"
                                class=""
                        >

                            <b-form-input
                                    :placeholder="$t('label_website')"
                                    v-model="itemData.site"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            name="REGON"
                            slim
                            rules=""
                    >
                        <b-form-group
                                label="REGON"
                                class=""
                        >

                            <b-form-input
                                    placeholder="REGON"
                                    v-model="itemData.regon"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_post_code')"
                            slim
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_post_code')"
                                class="required"
                        >

                            <b-form-input
                                    :placeholder="$t('label_post_code')"
                                    v-model="itemData.post_code"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_nip')"
                            slim
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_nip')"
                                class="required"
                        >

                            <b-form-input
                                    :placeholder="$t('label_nip')"
                                    v-model="itemData.nip"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_krs')"
                            slim
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_krs')"
                                class="required"
                        >

                            <b-form-input
                                    :placeholder="$t('label_krs')"
                                    v-model="itemData.krs"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_account_number')"
                            slim
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_account_number')"
                                class="required"
                        >

                            <b-form-input
                                    :placeholder="$t('label_account_number')"
                                    v-model="itemData.account_number"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_bank_swift')"
                            slim
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_bank_swift')"
                                class="required"
                        >

                            <b-form-input
                                    :placeholder="$t('label_bank_swift')"
                                    v-model="itemData.bank_swift"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ $t('label_save') }}
                        </b-button>
                        <b-button @click.prevent="$router.go(-1)"
                                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                  type="button"
                                  variant="outline-secondary"

                        >{{ $t('label_cancel') }}
                        </b-button>
                    </div>
                </validation-observer>
            </b-card-body>
        </b-card>

    </div>
</template>

<script>

    import {
        BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
        BFormGroup,
        BFormInput, BFormInvalidFeedback, BFormTextarea

    } from 'bootstrap-vue'

    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            BFormGroup,
            BFormInput, BFormInvalidFeedback,
            dynamicItemsBlock,
            ValidationProvider, ValidationObserver, BFormTextarea
        },
        props: ['selectOption'],

        data() {
            return {
                PREFIX: 'invoice_details',


                parentOption: {},

                blankItemData: {
                    id: 0,
                    company:'',
                    address:'',
                    post_code:'',
                    nip:'',
                    phone:'',
                    site:'',
                    email:'',
                    regon:'',
                    krs:'',
                    account_number:'',
                    bank_swift: ''
                },
                itemData: {}
            }

        },

        created() {

            this.getPageData();
        },

        methods: {

            getPageData(){

                this.async('get', '/' + this.PREFIX, {params:{}}, function(res){
                    // this.itemData = Object.assign({}, this.blankItemData);
                    let editedItem = res.data[0];
                    let item = Object.assign({}, this.blankItemData);
                    if(typeof editedItem == 'object') {
                        item.id = editedItem.id;
                        for (let prop in item) {
                            if (editedItem.hasOwnProperty(prop)) {
                                item[prop] = editedItem[prop];
                            }
                        }
                    }

                    this.itemData = item;
                    // console.log(this.itemData);
                });
            },

            onSubmit() {

                this.async('post', '/' + this.PREFIX, this.itemData, function (resp) {
                    this.getPageData();
                });

                // this.async('post', '/' + this.PREFIX , formData, function (resp) {
                //     this.getPageData();
                // }, false, {headers:{'Content-Type': 'multipart/form-data'}});

            }

        }


    }
</script>

